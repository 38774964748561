/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import ChevronRight from 'img/chevron-right.svg'

import ParseContent from 'components/shared/ParseContent'

const Faq = ({ className, faqIds }) => {
  const { questions } = useStaticQuery(graphql`
    {
      questions: allWordpressWpVeelgesteldeVragen(
        sort: { fields: date, order: DESC }
      ) {
        edges {
          node {
            wpId: wordpress_id
            title
            path
            content
          }
        }
      }
    }
  `)

  let faqQuestion = questions.edges

  if(faqIds && faqIds.length > 0) {
    faqQuestion = questions.edges.filter(({ node: { wpId } }) => faqIds.indexOf(wpId) !== -1)
  }

  return (
    <FaqBox className="px-4 pt-4 pb-1">
      {faqQuestion.map(({ node: { wpId, title, path, content } }) => (
        <Accordion allowZeroExpanded="true" className="mb-4" key={wpId}>
          <AccordionItem className="cursor-pointer">
            <Itemheading className="pb-2">
              <ItemButton>
                <Image src={ChevronRight} alt="" />
                {title}
              </ItemButton>
            </Itemheading>
            <AccordionItemPanel className="pl-4">
              <ParseContent content={content} />
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      ))}
    </FaqBox>
  )
}

const FaqBox = styled.div`
  background-color: ${props => props.theme.color.light};
`
const Itemheading = styled(AccordionItemHeading)`
  cursor: pointer;
  
`
const Image = styled.img`
  width: 14px;
  margin-right: 15px;
`

const ItemButton = styled(AccordionItemButton)`
  outline: 0;
`

export default Faq
