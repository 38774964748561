import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'

// Components
import ButtonDefault from 'components/elements/ButtonDefault'

// Third Party
import styled from 'styled-components'

const StyledKlantenVertellen = styled.div`
  //max-width: 1680px;
  position: relative;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
`

const Top = styled.div`
  background-color: ${({ theme }) => theme.color.contrast};
  color: ${({ theme }) => theme.color.light};

  & h2 {
    font-size: ${({ theme }) => theme.font.size.xxl};
    font-weight: ${({ theme }) => theme.font.weight.xl};
    text-align: center;
    margin-bottom: 0;
    font-family: ${({ theme }) => theme.font.family.secondary};

    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.l};
    }
  }
`

const StyledImage = styled(Image)`
  position: absolute !important;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
`

const Content = styled.div`
  height: fit-content;
`

const Block = styled.div`
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  background-color: ${({ theme }) => theme.color.light};
  padding: 25px;
  border-bottom-left-radius: 15px;

  & h2 {
    font-size: ${({ theme }) => theme.font.size.xxxl};
    font-weight: ${({ theme }) => theme.font.weight.xl};
    color: ${({ theme }) => theme.color.contrast};
    font-family: ${({ theme }) => theme.font.family.secondary};
    text-align: center;
  }

  & h3 {
    text-align: center;
    padding-bottom: 20px;
    font-size: 20px;
    font-weight: ${({ theme }) => theme.font.weight.s};
    color: ${({ theme }) => theme.color.dark};
    position: relative;

    &::before {
      content: '';
      position: absolute;
      bottom: 0px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      height: 1px;
      width: 50%;
      background-color: ${({ theme }) => theme.color.dark};
      opacity: 0.5;
    }
  }

  & h4 {
    font-size: 24px;
    text-align: center;
    color: ${({ theme }) => theme.color.text.main};

    @media (max-width: 991px) {
      font-size: 18px;
    }
  }

  & p {
    text-align: center;
    font-style: italic;
  }

  @media screen and (max-width: 991px) {
    padding: 25px 15px;
  }
`

const Button = styled.a`
  background-color: ${(props) => props.theme.color.contrast};
  display: inline-block;
  transition: transform 0.25s;

  &:hover {
    transform: scale(1.05);
  }

  font-family: ${(props) => props.theme.font.family.secondaryAlt};
  font-size: ${(props) => props.theme.font.size.m};
  color: ${(props) => props.theme.color.text.light} !important;
  padding: 10px 15px;
  height: 100%;
  display: flex;
  align-items: center;
`

const KlantenVertellen = ({ xml }) => {
  const { file } = useStaticQuery(graphql`
    {
      file(name: { eq: "wbw-klantenvertellen" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <StyledKlantenVertellen>
      <Top className="py-4">
        <h2>Klantbeoordelingen</h2>
      </Top>
      <Content className="position-relative py-lg-5">
        <div className="container py-lg-5">
          <div className="row py-5 justify-content-between">
            <div className="col-lg-5 pb-lg-0 pb-5">
              <Block className="h-100 d-flex flex-column justify-content-between">
                <h3>Gemiddelde Beoordeling</h3>
                <h2>
                  {xml && xml.ReviewCount?.Score
                    ? xml.ReviewCount.Score
                    : `...`}
                </h2>
                <h4 className="pt-4">
                  {xml && xml?.Reviews
                    ? `${xml?.Reviews?.length} beoordelingen`
                    : `...`}
                </h4>
                <div className="d-flex pt-5 justify-content-center">
                  <Button
                    target="_blank"
                    href="https://www.advieskeuze.nl/hypotheekadvies/3752am/welbijwim/beoordelingen?f=LwmBhCsXugx89Zu6xl7lt2o8kEoL2Zaanh42N3AAP7jawohHlWKPidN%2BuGcFP97j8CYcd6xVzir4Bh%2FzJH3OT%2F2Wte2ytuMdg4unonfm7SFuRVKm0sd0tF2U9QNKfek0&g=Bunschoten"
                  >
                    Alle beoordelingen
                  </Button>
                </div>
              </Block>
            </div>
            <div className="col-lg-5">
              <Block className="h-100 d-flex flex-column justify-content-between">
                <h3>Laatste Beoordeling</h3>
                <h2>
                  {xml && xml?.Reviews ? xml?.Reviews?.[0]?.Score * 2 : `...`}
                </h2>
                <p className="pt-4">
                  {xml && xml?.Reviews ? `"${xml?.Reviews?.[0]?.Text}"` : ``}
                </p>
                <p className="pt-4">
                  {xml && xml?.Reviews
                    ? `"${xml?.Reviews?.[0]?.Reviewer}"`
                    : ``}
                </p>
              </Block>
            </div>
          </div>
        </div>
        <StyledImage fluid={file.childImageSharp.fluid} alt="" />
      </Content>
    </StyledKlantenVertellen>
  )
}

export default KlantenVertellen
