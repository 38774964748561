/* eslint-disable import/order */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'

// Third Party
import styled from 'styled-components'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import CustomLink from 'components/shared/CustomLink'
import BlogPosts from 'components/BlogPosts'
import Faq from 'components/Faq'
import Testimonials from 'components/Testimonials'

// Elements
import HeroLanding from '../components/elements/HeroLanding'
import ButtonDefault from 'components/elements/ButtonDefault'
import TitleDefault from 'components/elements/TitleDefault'
import ContentDefault from 'components/elements/ContentDefault'
import HomeModal from 'components/elements/HomeModal'
import KlantenVertellen from '../components/KlantenVertellen'

// Images
import ChevronRight from 'img/chevron-right.svg'

const Container = styled.div`
  position: relative;
  z-index: 1;
`
const Characteristics = styled.div`
  position: relative;
  z-index: 1;
`
const Character = styled.div`
  background-color: ${(props) => props.theme.color.contrast};
  color: ${(props) => props.theme.color.light};
  text-align: right;
  position: relative;

  &::after {
    content: '';
    background-color: ${(props) => props.theme.color.contrast};
    width: 3100px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 100%;
  }

  & p {
    font-family: ${(props) => props.theme.font.family.secondaryAlt};
    font-size: ${(props) => props.theme.font.size.xxl};
    margin-bottom: 0;
    @media screen and (max-width: 768px) {
      font-size: ${(props) => props.theme.font.size.xl};
    }
  }

  @media (min-width: 991px) {
    &:first-child,
    &:last-child {
      margin-left: -10rem;
    }
  }
`

const Service = styled.div`
  background-color: ${(props) => props.theme.color.light};
  box-shadow: 0px 0px 4px #00000080;
  border-bottom-left-radius: 20px;
  transition: transform 0.25s;

  &:hover {
    transform: scale(1.05);
  }

  @media (min-width: 991px) {
    max-width: 319px;
  }
`
const ServiceTitle = styled.h3`
  background-color: ${(props) => props.theme.color.grey};
  font-family: ${(props) => props.theme.font.family.secondaryAlt};
  font-size: ${(props) => props.theme.font.size.xm};
  color: ${(props) => props.theme.color.text.secondary};
`

const ServiceImage = styled.div`
  position: relative;
`
const BlogSection = styled.section`
  position: relative;
  background-color: ${(props) => props.theme.color.secondary};
`

const FaqTitle = styled.div`
  font-family: ${(props) => props.theme.font.family.secondaryAlt};
  font-size: ${(props) => props.theme.font.size.xm};
  color: ${(props) => props.theme.color.text.light};
  background-color: ${(props) => props.theme.color.contrast};
`
const FaqBox = styled.div`
  position: relative;
`

const CtaBox = styled.div`
  background-color: ${(props) => props.theme.color.text.secondary};
  color: ${(props) => props.theme.color.text.light};
  position: relative;

  & p {
    font-size: ${(props) => props.theme.font.size.sm};
    color: ${(props) => props.theme.color.text.light};
  }
`

const CtaTitle = styled.p`
  font-family: ${(props) => props.theme.font.family.secondaryAlt};
  font-size: ${(props) => props.theme.font.size.xm} !important;
`

const HomeTemplate = ({
  data: {
    page: { path, yoastMeta, acf },
    services: { edges: services },
  },
}) => {
  const [reviews, setReviews] = React.useState()

  async function get() {
    const request = await fetch(
      'https://api.advieskeuze.nl/feed/reviews/81983b7a-4d36-43ba-90f7-a240b7ad4d1a?json=true'
    )
    const response = await request.json()

    setReviews(response)
    return response
  }

  React.useEffect(() => {
    get()
  }, [])

  return (
    <Layout>
      <SEO
        yoast={{ meta: yoastMeta }}
        pathname={path}
        image={acf.bannerImage.localFile.childImageSharp.fluid.src}
        article
      />
      <HeroLanding
        image={acf.bannerImage}
        title={acf.bannerTitle}
        linkWithIcon={{
          link: {
            text: acf.bannerButtonText,
            url: acf.bannerButtonLink,
          },
        }}
      />
      <section>
        <Container className="container py-5">
          <div className="row">
            <TitleDefault h1 className="mb-3 mb-lg-5 pl-lg-5 ml-lg-5">
              {acf.intro.title}
            </TitleDefault>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-7 pr-0 pr-lg-5">
              <Characteristics>
                {acf.intro.characteristic.map(({ value }) => (
                  <Character className="col-12 mb-4 mb-lg-5 py-1" key={value}>
                    <p>{value}</p>
                  </Character>
                ))}
              </Characteristics>
            </div>
            <div className="col-lg-5 mt-3 mt-lg-0">
              <ContentDefault content={acf.intro.description} />
            </div>
          </div>
        </Container>
      </section>
      <section className="mb-5">
        <Container className="container py-5">
          <div className="row">
            <TitleDefault stripe className="mb-5">
              {acf.services.title}
            </TitleDefault>
          </div>
          <div className="row">
            {services.map(({ node }) => (
              <div key={node.wpId} className="col-lg-4 col-md-6">
                <Service className="mb-5">
                  <CustomLink to={node.path}>
                    <ServiceTitle className="text-right pr-4 py-2">
                      {node.acf.excerpt.title}
                    </ServiceTitle>
                    <ServiceImage className="d-flex justify-content-between align-items-end py-4 px-3">
                      <img src={ChevronRight} alt="" />
                      <img
                        src={node.acf.excerpt.icon.localFile.publicURL}
                        alt=""
                      />
                    </ServiceImage>
                  </CustomLink>
                </Service>
              </div>
            ))}
          </div>
        </Container>
      </section>
      <section>
        <KlantenVertellen xml={reviews} />
      </section>
      <BlogSection id="questions">
        <Container className="container py-5">
          <div className="row py-4">
            <div className="col-lg-8 pr-lg-5">
              <BlogPosts
                postIds={acf.blog.posts.map(({ relation }) => relation)}
              />
            </div>
            <div className="col-lg-4 mt-5 mt-lg-0">
              <FaqBox>
                <FaqTitle className="text-center py-2">
                  {acf.faq.title}
                </FaqTitle>
                <Faq
                  faqIds={acf.faq.questions.map(({ relation }) => relation)}
                />
              </FaqBox>
              <CtaBox className="my-4 py-4 text-center">
                <CtaTitle className="mb-1">{acf.cta.title}</CtaTitle>
                <ContentDefault content={acf.cta.text} className="mb-2" />
                <ButtonDefault to={acf.cta.button.link}>
                  {acf.cta.button.text}
                </ButtonDefault>
              </CtaBox>
            </div>
          </div>
        </Container>
      </BlogSection>
      <section>
        <Testimonials
          id={[acf.testimonial.quote]}
          image={acf.testimonial.image.localFile.childImageSharp.fluid}
        />
      </section>
      <HomeModal />
    </Layout>
  )
}

export default HomeTemplate
