/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

// Elements
import CustomLink from 'components/shared/CustomLink'
import ContentDefault from 'components/elements/ContentDefault'
import BrandTitle from 'components/elements/BrandTitle'

// Images
import ChevronRight from 'img/chevron-right.svg'

const StyledHeroLanding = styled.div`
  position: relative;

  @media (min-width: 1200px) {
    height: 70vh;
    min-height: 550px;
    max-height: 650px;
  }

  @media (max-width: 1199px) {
    height: 70vh;
    min-height: 450px;
    max-height: 500px;
  }

  @media (max-width: 991px) {
    min-height: 450px;
    max-height: 450px;
  }

  @media (max-width: 767px) {
    min-height: 300px;
    max-height: 300px;
  }
`

const Image = styled.div`
  position: absolute;
  left: auto;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
`

const BgImage = styled(BackgroundImage)`
  height: 650px;
  width: 100%;
  z-index: 1;

  @media (max-width: 1199px) {
    height: 100%;
  }

  &::before {
    border-bottom-left-radius: 25px;
  }
`

const Title = styled(ContentDefault)`
  & h1 {
    font-family: ${props => props.theme.font.family.secondary};
    color: ${props => props.theme.color.text.secondary};
    font-size: ${props => props.theme.font.size.sl};

    @media (min-width: 992px) {
      font-size: ${props => props.theme.font.size.xm};
    }

    @media (max-width: 991px) {
      font-size: ${props => props.theme.font.size.xsm};
      line-height: 22px;
    }
  }
`

const Content = styled.div`
  position: relative;
  z-index: 2;
  background-color: ${props => props.theme.color.light};
  box-shadow: 0px 0px 4px #00000080;
  border-bottom-left-radius: 20px;
  margin-bottom: 0;

  & > h1 {
    font-family: ${props => props.theme.font.family.secondary};
    color: ${props => props.theme.color.text.secondary};
    font-size: ${props => props.theme.font.size.sl};
  }

  @media screen and (max-width: 991px) {
    width: 100%;
    text-align: center;

    & > h1 {
      font-size: ${props => props.theme.font.size.xxl};
    }
  }
`

const HeroLanding = ({ image, title, className, linkWithIcon }) => (
  <StyledHeroLanding className={className}>
    <Image className="col-md-12 col-lg-8 p-0">
      <BgImage fluid={image.localFile.childImageSharp.fluid} />
    </Image>
    <div className="container d-flex align-items-center h-100 pt-lg-5">
      {title && (
        <Content className="col-lg-4 p-4">
          <Title content={title} />
          <BrandTitle />
        </Content>
      )}
      {linkWithIcon && linkWithIcon.link && (
        <OrangeBox className="col-lg-3 pt-5 pb-3">
          <Icon src={ChevronRight} alt="" />
          <Link to={linkWithIcon.link.url}>{linkWithIcon.link.text}</Link>
        </OrangeBox>
      )}
    </div>
  </StyledHeroLanding>
)

const OrangeBox = styled.div`
  background-color: ${props => props.theme.color.contrast};
  margin-left: -11rem;
  margin-top: 9rem;
  border-bottom-left-radius: 15px;
`
const Link = styled(CustomLink)`
  color: ${props => props.theme.color.text.light};
  &:hover {
    color: ${props => props.theme.color.text.light};
    text-decoration: underline;
  }
`

const Icon = styled.img`
  width: 20px;
  margin-right: 11px;
`

export default HeroLanding
