/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

// Elements
import TitleDefault from 'components/elements/TitleDefault'
import ContentDefault from 'components/elements/ContentDefault'
import CustomLink from 'components/shared/CustomLink'
import BrandTitle from 'components/elements/BrandTitle'

import { getLanguage } from 'services/language'

const BlogPosts = ({ className, postIds, vertical }) => {
  const { posts } = useStaticQuery(graphql`
    {
      posts: allWordpressPost(sort: { fields: date, order: DESC }) {
        edges {
          node {
            wpId: wordpress_id
            title
            path
            acf {
              excerpt {
                image {
                  localFile {
                    childImageSharp {
                      fluid(quality: 100, maxWidth: 320) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
                description
              }
            }
            locale: wpml_current_locale
          }
        }
      }
    }
  `)
  
  const currentLocale = getLanguage()
  let blogPosts = posts.edges
  

  if(postIds && postIds.length > 0) {
    blogPosts = posts.edges.filter(({ node: { wpId } }) => postIds.indexOf(wpId) !== -1)
  }

  blogPosts = blogPosts.filter(({ node: { locale } }) => locale === currentLocale)

  return (
    <div>
      {vertical ? (
        blogPosts.map(({ node: { wpId, title, path, acf } }) => (
          <Post className={`col-lg-11 p-0 mb-5 d-flex  ${className}`} key={wpId}>
            <CustomLink to={path} className="row mx-0 d-flex flex-column">
              <ImageBox className="col p-0">
                <VerticalImage fluid={acf.excerpt.image.localFile.childImageSharp.fluid} />
              </ImageBox>
              <ContentBox className="col p-4">
                <BrandTitle />
                <Title className="mb-3">{title}</Title>
                <Content content={acf.excerpt.description} />
              </ContentBox>
            </CustomLink>
          </Post>
        ))
      ) : (
        blogPosts.map(({ node: { wpId, title, path, acf } }) => (
          <Post className={`mb-4 d-flex ${className ? `${className}` : ``}`} key={wpId}>
            <CustomLink to={path} className="d-flex flex-wrap">
              <ImageBox className="col-md-3 p-0">
                <Image fluid={acf.excerpt.image.localFile.childImageSharp.fluid} />
              </ImageBox>
              <ContentBox className="col-md-9 p-4">
                <BrandTitle />
                <Title className="mb-3">{title}</Title>
                <Content content={acf.excerpt.description} />
              </ContentBox>
            </CustomLink>
          </Post>
        ))
      )}  
     
    </div>
  )
}

const Post = styled.div`
  position: relative;
  background-color: ${props => props.theme.color.light};
`

const ImageBox = styled.div`
  position: relative;
`

const Image = styled(BackgroundImage)`
  width: 100%;
  padding-top: 100%;
  height: 100%;

  @media (max-width: 991px){
    padding-top: 40%;
  }
`


const VerticalImage = styled(BackgroundImage)`
  width: 100%;
  padding-top: 60%;
  height: 100%;

  @media (max-width: 991px){
    padding-top: 40%;
  }

`


const ContentBox = styled.div`
  position: relative;
`

const Title = styled(TitleDefault)`
  font-size: ${props => props.theme.font.size.xm};
  max-width: none;
  margin-left:0 !important;
`

const Content = styled(ContentDefault)`
  font-size: ${props => props.theme.font.size.sm};
  line-height: ${props => props.theme.font.size.l};

  & p {
    margin-bottom: 0;
  }
`


export default BlogPosts
