import React, { useState, useEffect } from 'react'
// import ReactDOM from 'react-dom'
import Modal from 'react-modal'

// Images
// import WBWPopup from 'img/popup-avond.png'
import WBWInformationNight from 'img/WBW-popup-Informatieavond-20-03.png'
import Close from 'img/x.svg'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
  },
}

function App() {
  const [modalIsOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      if (sessionStorage.getItem('hideModal') && sessionStorage.getItem('hideModal') !== 'true') {
        setIsOpen(true)
      }
    }, 2000)
  }, [])

  function closeModal() {
    sessionStorage.setItem('hideModal', 'true')
    setIsOpen(false)
  }

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Informatieavond Modal"
      >
        <div className="text-right">
          <button type="button" onClick={closeModal}>
            <img src={Close} alt="" />
          </button>
        </div>
        <a href="/starter-op-de-woningmarkt-welbijwim-helpt-je-graag/">
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <img
            src={WBWInformationNight}
            className="modal-image"
            width="700"
            alt=""
            onClick={closeModal}
            onKeyDown={closeModal}
          />
        </a>
      </Modal>
    </div>
  )
}

export default App
